import store from "@/state/store";
import { KcmContent, KcmNetworkOptions } from "@/types";
import {
  TwitterApiShareOptions,
  TwitterPageResponse,
  TwitterStatusUpdateResponse,
  TwitterVideoUploadResponse,
} from "@/types/twitter.types";
import axios, { AxiosResponse } from "axios";
import { thirdPartyService } from "../internal-api/thirdparty.service";
import {
  KcmSocialPageData,
  KcmSocialPromise,
  ServiceData,
} from "@/types/social-media.types";

const BASE_URL = process.env.VUE_APP_SERVICES_API_ROOT
  ? process.env.VUE_APP_SERVICES_API_ROOT.replace(/\/$/, "")
  : "";
const HOST = BASE_URL + "/twitter/v1/industries/1/";

const serviceData: ServiceData = {
  initiated: false,
  pages: [],
};

export const twitterService = {
  name: "twitter",
  login,
  init,
  connect,
  disconnect,
  post,
  update,
  remove,
  getPages,
  checkAccess,
  serviceData,
};

function checkAccess(): boolean {
  const hasToken = store.getters["auth/getSocialToken"]("twitter");
  return hasToken !== null;
}

function login(): Promise<KcmSocialPromise> {
  return new Promise((resolve) =>
    resolve({ success: false, error: "login is not functional for twitter" })
  );
}

function init(): boolean {
  serviceData.initiated = true;
  return serviceData.initiated;
}

async function connect(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    const queryVars = decodeURI(window.location.search);

    const extraChar = queryVars ? "&" : "?";

    const redirect =
      process.env.VUE_APP_URL +
      location.pathname.replace("/", "") +
      queryVars +
      extraChar +
      "media=Twitter&sync=services";

    const params = new URLSearchParams({
      userId: store.getters["auth/authUserId"],
      redirect: redirect,
      access_token: store.getters["auth/authToken"],
    });

    const authUrl = HOST + "oauth/request_token?" + params.toString();
    window.location.href = authUrl;

    resolve({ success: true });
  });
}

async function disconnect(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => resolve({ success: true }));
}

async function post(
  content: KcmContent,
  shareType: string,
  networkOptions?: KcmNetworkOptions
): Promise<KcmSocialPromise> {
  if (["blog", "video", "videos"].includes(shareType)) {
    let contentUrl = store.getters["profile/profileSTMBlogLink"](
      content,
      store.getters["settings/contentLanguageSetting"]
    );

    if (shareType.startsWith("video")) {
      contentUrl = store.getters["profile/profileSTMLinkExtended"](
        "videos/" + content.slug,
        store.getters["settings/contentLanguageSetting"]
      );
    }
    simpleShare(contentUrl, content.title);
    return new Promise((resolve) =>
      resolve({ success: true, data: "Simple Share initiated" })
    );
  } else if (["realtalk_video"].includes(shareType)) {
    const postData: TwitterApiShareOptions = {
      token: store.getters["auth/getServiceByField"](
        twitterService.name,
        "token"
      ),
      secret: store.getters["auth/getServiceByField"](
        twitterService.name,
        "secret"
      ),
      status: networkOptions?.message ?? ".",
    };

    if (!postData.token || !postData.secret) {
      throw new Error("Missing required authorization to post to X");
    }

    const response = (await axiosPost("media/upload", {
      token: postData.token,
      secret: postData.secret,
      status: postData.status,
      media: [content.contents],
    }).catch((error) => error.response)) as TwitterVideoUploadResponse;

    if (!response || !response.data.data?.id) {
      const errorMessage =
        response?.data?.message ?? "Unexpected error during video upload";
      return Promise.resolve({
        success: false,
        error: errorMessage,
      });
    }

    return Promise.resolve({
      success: true,
      data: response,
    });
  } else if (
    [
      "social-graphics-single",
      "infographics",
      "slide",
      "social-graphics-multi",
      "mmr",
      "local",
    ].includes(shareType)
  ) {
    const postData: TwitterApiShareOptions = {
      token: store.getters["auth/getServiceByField"](
        twitterService.name,
        "token"
      ),
      secret: store.getters["auth/getServiceByField"](
        twitterService.name,
        "secret"
      ),
      status: networkOptions?.message ?? ".",
    };

    postData.media = content.contents.split(",");

    const response = (await axiosPost("statuses/update", postData).catch(
      (error) => {
        return error.response;
      }
    )) as TwitterStatusUpdateResponse;

    if (response.data.message || !response.data.id) {
      let extendErr = "General error";
      if (response.data.message) {
        extendErr = response.data.message;
      }
      return new Promise((resolve) => {
        resolve({
          success: false,
          error: extendErr,
        });
      });
    }

    return new Promise((resolve) => {
      resolve({
        success: true,
        data: response,
      });
    });
  }

  return new Promise((resolve) => {
    resolve({
      success: false,
      error: "Content type not set up to share via twitter",
    });
  });
}

async function update(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    resolve({
      success: false,
      error: "No update for twitter",
    });
  });
}

async function remove(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    resolve({
      success: false,
      error: "No remove for twitter",
    });
  });
}

function simpleShare(contentUrl: string, title: string): void {
  const url =
    "https://twitter.com/intent/tweet?text=" +
    encodeURIComponent(title) +
    "&url=" +
    encodeURIComponent(contentUrl);

  window.open(url, "sharer", "toolbar=0,status=0,width=700,height=500");
}

async function getPages(): Promise<KcmSocialPromise> {
  const postData = {
    token: store.getters["auth/getServiceByField"](
      twitterService.name,
      "token"
    ),
    secret: store.getters["auth/getServiceByField"](
      twitterService.name,
      "secret"
    ),
  };
  const response = (await axiosPost(
    "account/verify_credentials",
    postData
  ).catch((error) => {
    return error.response;
  })) as TwitterPageResponse;

  if (
    response.data.errors ||
    !response.data.screen_name ||
    !response.data.id_str
  ) {
    let extendErr = "generic error";
    if (response.data.errors) {
      extendErr = response.data.errors[0].message;
    }
    return new Promise((resolve) => {
      resolve({
        success: false,
        error: "Error getting handle from twitter: " + extendErr,
      });
    });
  }

  const finalResponse: KcmSocialPageData[] = [
    { text: "@" + response.data.screen_name, value: response.data.id_str },
  ];

  serviceData.pages = finalResponse;

  return new Promise((resolve) => {
    resolve({ success: true, data: finalResponse });
  });
}

function axiosPost(
  resource: string,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data: any
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>> {
  return axios.post(HOST + resource, data, { ...thirdPartyService.HEADERS() });
}
