import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/profile/basic",
    name: "Personalization Profile",
    meta: { authRequired: true },
    component: () => import("@/router/views/profile/pp/basic.vue"),
  },
  {
    path: "/profile/blog",
    name: "Blog Settings",
    meta: { authRequired: true },
    component: () => import("@/router/views/profile/pp/blog.vue"),
  },
  {
    path: "/profile/graphics",
    name: "Graphics Settings",
    meta: { authRequired: true },
    component: () => import("@/router/views/profile/pp/graphics.vue"),
  },
  {
    path: "/profile/guides",
    name: "Guides Settings",
    meta: { authRequired: true },
    component: () => import("@/router/views/profile/pp/guides.vue"),
  },
  {
    path: "/profile/email-builder",
    name: "Email Builder Settings",
    meta: { authRequired: true, featureRequired: "email-builder" },
    component: () => import("@/router/views/profile/pp/email.vue"),
  },
  {
    path: "/profile/videos",
    name: "Video Settings",
    meta: { authRequired: true, featureRequired: "video" },
    component: () => import("@/router/views/profile/pp/videos.vue"),
  },
];

const redirects: RouteConfig[] = [
  { path: "/profile", redirect: "/profile/basic" },
  { path: "/profile/email", redirect: "/profile/email-builder" },
  { path: "/profile/newsletter", redirect: "/profile/email-builder" },
];

const profileRoutes = [...routes, ...redirects];

export default profileRoutes;
