import { KcmContent } from "@/types";
import { ref, computed, watch, set } from "vue";
import { contentService } from "@/services/internal-api/content.service";
import store from "@/state/store";
import { profileService } from "@/services/internal-api/profile.service";

const isShowingMushModal = ref<boolean>(false);
const currentContent = ref<KcmContent>({} as KcmContent);
const mushContentLoaded = ref<boolean>(false);
const mushContent = ref<KcmContent>({} as KcmContent);
const lastChecked = ref<number>(0);
const mushContentArray = computed((): KcmContent[] => {
  return mushContent.value?.related ?? [];
});

// these are the profile flag IDs for MUSH1 - MUSH4
// this is dumb and I am dumb for making this.
// one day we should change the endpoints to take a name/slug of the flag to add
// instead of using IDs that have to be the same on prod & staging.
const flagIds = [42923, 42924, 42925, 42926];

const allCompletedSteps = ref<Record<string, boolean[]>>({});

function checkCompletedSteps(mush: KcmContent): void {
  const newCompletedSteps = { ...allCompletedSteps.value };
  newCompletedSteps[mush.id] = flagIds.map((id: number) =>
    store.getters["profile/flagAddedSince"](id, mush.published_at)
  );
  allCompletedSteps.value = newCompletedSteps;
}

/**
 * Computed list of booleans showing whether or not the flags corresponding to
 * each step of a MUSH have been completed since the timestamp of the the most
 * recent MUSH being published.
 */
const completedSteps = computed((): boolean[] => {
  return allCompletedSteps.value[mushContent.value.id] ?? [];
});

/**
 * If current mush content is not from today and is over 15 minutes old OR
 * the force update is set to true, fetch the most recent published
 * MUSH content from the content API and update the lastUpdated timestamp
 * @param forceUpdate
 * @returns
 */
async function updateMushContent(forceUpdate: boolean = false): Promise<void> {
  const currentDate = new Date();
  const publishedDate = mushContent.value?.published_at
    ? new Date(mushContent.value.published_at)
    : undefined;
  const isPublishedToday =
    publishedDate?.toDateString() === currentDate.toDateString();
  const fifteenMinutes = 15 * 60 * 1000;

  if (isPublishedToday && !forceUpdate) {
    return;
  }

  //publishedDate && publishedDate < currentDate &&
  if (
    !publishedDate ||
    forceUpdate ||
    Date.now() - lastChecked.value > fifteenMinutes
  ) {
    try {
      const language = store.getters["settings/contentLanguageSetting"];
      mushContentLoaded.value = false;
      const mush = await contentService.getLatestMushContent({
        language: language,
      });
      if (mush?.id) {
        // Temp fix to remove anything that is custom content
        const filteredMush = mush.related?.filter((content) => {
          if (/\d{2,}/.test(content.slug)) return false;
          return true;
        });
        mush.related = filteredMush;
        mushContent.value = mush;
        checkCompletedSteps(mush);
      }
    } catch (err) {
      console.log("Failed to get MUSH: ", err);
    }
    mushContentLoaded.value = true;
    lastChecked.value = Date.now();
  }
}

function mushIndexFromContent(checkContent: KcmContent): number {
  return mushContentArray.value.findIndex((content) => {
    return (
      content.id === checkContent.id ||
      parseInt(content.id) === checkContent.based_on
    );
  });
}
function isPieceOfMush(content: KcmContent): boolean {
  return mushIndexFromContent(content) > -1;
}

/**
 * Takes in a piece of content, gets its corresponding index from the array
 * of current MUSH - marks that step as completed for the day
 * @param content
 */
async function completeStep(content: KcmContent): Promise<void> {
  currentContent.value = content;
  const stepNumber = mushIndexFromContent(content);
  const mushId = mushContent.value.id;

  if (stepNumber > -1 && !allCompletedSteps.value[mushId][stepNumber]) {
    const userId = store.getters["auth/authUserId"];
    //make profile API call to update timestamp on flag
    await profileService.addProfileFlag(`${userId}`, flagIds[stepNumber]);

    //mark this step as done for the current mush ID
    const updatedCompletedSteps = { ...allCompletedSteps.value };
    if (!updatedCompletedSteps[mushId]) {
      updatedCompletedSteps[mushId] = [];
    }
    updatedCompletedSteps[mushId][stepNumber] = true;
    allCompletedSteps.value = updatedCompletedSteps;

    isShowingMushModal.value = true;
  }
}

export {
  currentContent,
  mushContent,
  mushContentArray,
  isShowingMushModal,
  completedSteps,
  mushContentLoaded,
  updateMushContent,
  isPieceOfMush,
  completeStep,
};
