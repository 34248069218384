import {
  BillingGenericError,
  BillingGenericNoData,
  KcmCouponData,
  KCMSubscriptionCreateChangeData,
  KCMSubscriptionCreateData,
  KCMSubscriptionUpdateData,
  PlanData,
} from "@/types";
import {
  RecurlySubscriptionData,
  RecurlyAccountData,
  RecurlyPlanData,
  RecurlyCouponData,
  RecurlyAccountBalanceData,
  RecurlyPurchaseData,
  RecurlyPendingChangeData,
  RecurlySubscriptionResponse,
} from "@/types/recurly.types";
import { ElementsInstance } from "@recurly/recurly-js";
import axios, { AxiosResponse } from "axios";
import { thirdPartyService } from "../internal-api/thirdparty.service";
import store from "@/state/store";

const baseUrl = process.env.VUE_APP_SERVICES_API_ROOT
  ? process.env.VUE_APP_SERVICES_API_ROOT.replace(/\/$/, "")
  : "";
const HOST = `${baseUrl}/recurly/v1/industries/1/`;

export const nullDate = "0001-01-01T00:00:00Z";

function serviceId(): string {
  return "recurly";
}

export const recurlyService = {
  serviceId,
  status,
  subscription_data,
  account_data,
  plans,
  coupon_codes,
  create_subscription,
  update_subscription,
  create_change_subscription,
  pause_subscription,
  resume_subscription,
  cancel_pending_changes,
  reactivate_subscription,
  update_account,
  validate_coupon,
  account_balance,
  redirects,
  parse_coupons,
  create_purchase,
  setup_card_element,
  get_prorated_charge,
  get_invoices,
  download_invoice,
};

function get(
  resource: string,
  additionalHeaders?: Record<string, string>
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>> {
  return axios.get(HOST + resource, {
    ...thirdPartyService.HEADERS(),
    ...additionalHeaders,
  });
}

function post(
  resource: string,
  data: unknown
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any> | { error: any }> {
  return axios.post(HOST + resource, data, { ...thirdPartyService.HEADERS() });
}

function put(
  resource: string,
  data: unknown
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any> | { error: any }> {
  return axios.put(HOST + resource, data, { ...thirdPartyService.HEADERS() });
}

function deleteAxios(
  resource: string
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any> | { error: any }> {
  return axios.delete(HOST + resource, { ...thirdPartyService.HEADERS() });
}

async function status(user_id: string): Promise<string> {
  // get subscription status
  return (await subscription_data(user_id)).subscription.state;
}

async function subscription_data(
  userId: string
): Promise<RecurlySubscriptionResponse> {
  if (store.getters["auth/authIsCrew"]) {
    const sub = getDummySubscriptions();
    return { subscription: sub, member_has_paid: true };
  }
  const data = (
    await get("accounts/" + userId + "/subscription").catch((error) => {
      console.log(error?.response?.data?.message);
    })
  )?.data;
  return data;
}

async function account_data(userId: string): Promise<RecurlyAccountData> {
  if (store.getters["auth/authIsCrew"]) {
    await store.dispatch("billing/setBillingSource", "recurly");
    const acc = getDummyAccountData();
    return acc;
  }
  const acc = (
    await get("accounts/" + userId).catch((error) => {
      if (
        !error?.response?.data?.message.includes(
          "Failed to get account: Couldn't find Account with code = "
        )
      )
        console.log(error?.response?.data?.message);
    })
  )?.data?.account;
  return acc;
}

async function plans(): Promise<RecurlyPlanData[]> {
  if (store.getters["auth/authIsCrew"]) {
    const plans = getDummyPlans();
    return plans;
  }
  const plans = (await get("plans")).data.plan;
  return plans;
}

async function coupon_codes(): Promise<RecurlyCouponData[]> {
  const data = (await get("coupons")).data.coupons;
  return data;
}

async function create_subscription(
  id: string,
  payload: KCMSubscriptionCreateData
): Promise<RecurlySubscriptionData | BillingGenericError> {
  const data: {
    plan_code: string;
    account: { code: string };
    currency: string;
    coupon_codes?: string;
    trial_ends_at?: string;
  } = {
    plan_code: payload.plan_code,
    account: { code: id },
    currency: "USD",
  };

  if (payload.coupon_codes) {
    data.coupon_codes = payload.coupon_codes?.join(",");
  }
  if (payload.trial_ends_at) {
    data.trial_ends_at = payload.trial_ends_at;
  }

  const response = await post("subscriptions", data).catch((err) => {
    return { error: err };
  });
  if ("error" in response) {
    return { error: response.error };
  }

  return response.data;
}

async function update_subscription(
  id: string,
  payload: KCMSubscriptionUpdateData
): Promise<RecurlySubscriptionData | BillingGenericError> {
  const data = {
    new_plan_code: payload.new,
    coupon: payload.coupon_codes?.join(",") || "",
    timeframe:
      store.getters["billing/isLegacy"] && payload.timeframe
        ? payload.timeframe
        : "",
  };

  const response = await post(`accounts/${id}/change_plan`, data).catch(
    (err) => {
      return { error: err };
    }
  );

  if ("error" in response) {
    return { error: response.error };
  }
  return response.data;
}

async function create_change_subscription(
  subscriptionId: string,
  payload: KCMSubscriptionCreateChangeData
): Promise<RecurlySubscriptionData | BillingGenericError> {
  const data = {
    timeframe: payload.timeframe,
    add_ons: payload.add_ons,
  };

  const response = await post(
    `subscriptions/${subscriptionId}/change`,
    data
  ).catch((err) => {
    return { error: err };
  });

  if ("error" in response) {
    return { error: response.error };
  }
  return response.data;
}

async function pause_subscription(
  subscriptionId: string,
  length: number,
  reason?: string
): Promise<RecurlySubscriptionData | BillingGenericError> {
  const data = {
    remaining_pause_cycles: length,
    reason: reason,
  };

  const response = await put(
    `subscriptions/${subscriptionId}/pause`,
    data
  ).catch((err) => {
    return Promise.reject({ error: err });
  });

  if ("error" in response) {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    return Promise.reject({ error: (response as any).error });
  }

  return response.data.subscription;
}
async function resume_subscription(
  subscriptionId: string
): Promise<RecurlySubscriptionData | BillingGenericError> {
  const response = await put(
    `subscriptions/${subscriptionId}/resume`,
    null
  ).catch((err) => {
    return { error: err };
  });

  if ("error" in response) {
    return { error: response.error };
  }

  return response.data.subscription;
}

async function cancel_pending_changes(
  subscriptionId: string
): Promise<null | BillingGenericError> {
  const response = await deleteAxios(
    `subscriptions/${subscriptionId}/change`
  ).catch((err) => {
    return { error: err };
  });

  if ("error" in response) {
    return { error: response.error };
  }

  return null;
}

async function reactivate_subscription(
  subscriptionId: string
): Promise<RecurlySubscriptionData | BillingGenericError> {
  const response = await put(
    `subscriptions/${subscriptionId}/reactivate`,
    {}
  ).catch((err) => {
    return { error: err };
  });

  if ("error" in response) {
    return { error: response.error };
  }
  return response.data.subscription as RecurlySubscriptionData;
}

async function update_account(
  id: string,
  payload: string
): Promise<null | BillingGenericError> {
  const token = { token_id: payload };
  const response = await post(`accounts/${id}/billing_info`, token).catch(
    (err) => {
      return { error: err };
    }
  );

  if ("error" in response) {
    return { error: response.error };
  }

  return null;
}

async function validate_coupon(
  code: string
): Promise<RecurlyCouponData | BillingGenericNoData> {
  const data = (await get("coupons")).data.coupons;
  const couponCode = data.filter(
    (coupon: RecurlyCouponData) =>
      coupon.state !== "expired" && coupon.code === code
  );
  if (couponCode.length < 1) {
    return { error: "invalid coupon" };
  }
  return couponCode[0];
}

async function account_balance(id: string): Promise<RecurlyAccountBalanceData> {
  if (store.getters["auth/authIsCrew"]) {
    return {
      object: "",
      account: await account_data(id),
      past_due: false,
      balances: [
        {
          currency: "USD",
          amount: 20,
          processing_prepayment_amount: 0,
        },
      ],
    };
  }
  const data = (await get("accounts/" + id + "/balance")).data;
  return data;
}

function redirects(): { [x: string]: string } {
  return {
    dunning: "/billing",
    upgrade: "/upgrade",
  };
}

function parse_coupons(couponData: RecurlyCouponData[]): KcmCouponData[] {
  const kcmCouponData: KcmCouponData[] = [];
  if (!couponData) return [];
  for (const coupon of couponData) {
    let value = "0";
    if (coupon.discount.type === "fixed") {
      const usCoupon =
        coupon.discount.currencies.filter(
          (currency) => currency.currency === "USD"
        )[0] || null;
      if (!usCoupon?.amount) {
        value = "0.00";
      } else {
        value = usCoupon.amount.toFixed(2);
      }
    } else if (coupon.discount.type === "percent") {
      value = coupon.discount.percent + "%";
    }

    let duration = coupon.duration;
    if (duration === "temporal") {
      duration =
        coupon.temporal_amount +
        " " +
        coupon.temporal_unit +
        (coupon.temporal_amount > 1 ? "s" : "");
    }

    kcmCouponData.push({
      id: coupon.id,
      code: coupon.code,
      name: coupon.name,
      state: coupon.state,
      type: coupon.discount.type,
      value: value,
      duration: duration,
      plans: coupon.applies_to_all_plans ? "all" : (coupon.plans as PlanData[]),
    });
  }

  return kcmCouponData;
}

async function create_purchase(
  payload: RecurlyPurchaseData
): Promise<void | BillingGenericError> {
  const response = await post("purchases", payload).catch((err) => {
    return { error: err };
  });

  if ("error" in response) {
    return { error: response.error };
  }

  return;
}

function setup_card_element(elementId: string): ElementsInstance {
  // eslint-disable-next-line
  window.recurly.configure(process.env.VUE_APP_RECURLY_PUBLIC_KEY as string);
  // eslint-disable-next-line
  const elements = window.recurly.Elements();
  const cardElement = elements.CardElement({
    style: {
      format: false,
      placeholder: {
        color: "black !important",
        content: {
          number: "Card number",
          cvv: "CVC",
        },
      },
      invalid: {
        fontColor: "red",
      },
    },
    number: {
      selector: "#recurly-number",
      format: true,
    },
    month: {
      style: {
        placeholder: {
          content: "MM",
        },
      },
    },
    year: {
      style: {
        placeholder: {
          content: "YYYY",
        },
      },
    },
    cvv: {},
  });
  cardElement.attach(elementId);

  return elements;
}

async function get_prorated_charge(
  user_id: string,
  new_plan_code: string,
  coupon: string
): Promise<number> {
  const response = await post(`accounts/${user_id}/prorated_amount`, {
    new_plan_code,
    coupon,
  }).catch((err) => {
    return { error: err };
  });

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  if ((response as any).data?.prorated_amount) {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    return (response as any).data.prorated_amount as number;
  }

  return 0;
}

async function get_invoices(
  user_id: string,
  params?: {
    limit?: number;
    ids?: number[];
    order?: string;
    sort?: string;
    beginTime?: string;
    endTime?: string;
    type?: string;
  }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Promise<Record<string, any>[]> {
  if (store.getters["auth/authIsCrew"]) {
    const invoices = getDummyInvoiceData();
    return invoices;
  }
  const url = "accounts/" + user_id + "/invoices";

  let queryString = "";
  if (params !== undefined) {
    queryString =
      "?" +
      Object.keys(params ?? {})
        .map((key) => {
          return key + "=" + params[key as keyof typeof params];
        })
        .join("&");
  }

  return await get(url + queryString)
    .then((resp) => {
      return resp.data;
    })
    .catch(() => {
      return [];
    });
}

async function download_invoice(
  user_id: string,
  invoice_number: number
): Promise<Blob> {
  const url = "accounts/" + user_id + "/invoices/" + invoice_number + "/pdf";

  return await get(url, {
    responseType: "blob",
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

// used to generate subscription, billing, and payment data so we can view
// billing and subscription pages in prod
function getDummyAccountData(): RecurlyAccountData {
  const address = {
    phone: "555-555-5555",
    street1: "8720 Stony Point Pkwy",
    street2: "",
    city: "Richmond",
    region: "east",
    postal_code: "23235",
    country: "USA",
  };
  const billing = {
    account_id: "",
    address: address,
    created_at: "",
    first_name: "",
    id: "",
    last_name: "",
    object: "",
    payment_method: {
      object: "",
      card_type: "visa",
      first_six: "411111",
      last_four: "1111",
      last_two: "11",
      exp_month: 6,
      exp_year: 26,
      gateway_token: "",
      cc_bin_country: "US",
      gateway_code: "",
      billing_agreement_id: "",
      name_on_account: "",
      account_type: "",
      routing_number: "",
      routing_number_bank: "",
    },
    primary_payment_method: true,
    updated_at: "",
    updated_by: { country: "US", ip: "0.0.0.0" },
    valid: true,
  };
  return {
    id: "fakeIdForKcmCrew",
    object: "",
    code: "",
    account_id: "",
    first_name: store.getters["auth/authFirstName"],
    last_name: store.getters["auth/authLastName"],
    company: "KCM",
    address: address,
    created_at: nullDate,
    updated_at: nullDate,
    billing_info: billing,
    has_live_subscription: true,
  };
}

function getDummyInvoiceData(): any {
  return {
    invoices: [
      {
        number: "8720",
        line_items: [{ description: "Fake KCM Plan" }],
        total: "99.99",
        date_created: "2024-01-15T00:00:00Z",
        created_at: "2024-01-15T00:00:00Z",
      },
      {
        number: "8721",
        line_items: [{ description: "Fake KCM Plan" }],
        total: "99.99",
        date_created: "2024-02-15T00:00:00Z",
        created_at: "2024-02-15T00:00:00Z",
      },
    ],
  };
}

function getDummyPlans(): RecurlyPlanData[] {
  return [
    {
      id: "fakeKcmPlan",
      object: "",
      code: "essentials-monthly",
      name: "KCM Essentials",
      currencies: [{ unit_amount: 49 }],
      video: "",
      description: "KCM essentials that is fake.",
      interval_length: 1,
      interval_unit: "month",
    },
    {
      id: "fakeKcmPlan",
      object: "",
      code: "expert-monthly",
      name: "KCM Expert",
      currencies: [{ unit_amount: 69 }],
      video: "",
      description: "KCM expert that is fake.",
      interval_length: 1,
      interval_unit: "month",
    },
    {
      id: "fakeKcmPlan",
      object: "",
      code: "elite-monthly",
      name: "KCM Eilte",
      currencies: [{ unit_amount: 99 }],
      video: "",
      description: "KCM elite that is fake.",
      interval_length: 1,
      interval_unit: "month",
    },
    {
      id: "fakeKcmPlan",
      object: "",
      code: "essentials-annual",
      name: "KCM Essentials",
      currencies: [{ unit_amount: 399 }],
      video: "",
      description: "KCM essentials that is fake.",
      interval_length: 12,
      interval_unit: "month",
    },
    {
      id: "fakeKcmPlan",
      object: "",
      code: "expert-annual",
      name: "KCM Expert",
      currencies: [{ unit_amount: 599 }],
      video: "",
      description: "KCM expert that is fake.",
      interval_length: 12,
      interval_unit: "month",
    },
    {
      id: "fakeKcmPlan",
      object: "",
      code: "elite-annual",
      name: "KCM Elite",
      currencies: [{ unit_amount: 899 }],
      video: "",
      description: "KCM elite that is fake.",
      interval_length: 12,
      interval_unit: "month",
    },
  ];
}

function getDummySubscriptions(): RecurlySubscriptionData {
  return {
    id: "",
    object: "",
    uuid: "",
    account: {} as RecurlyAccountData,
    plan: getDummyPlans()[0],
    state: "",
    coupon_redemptions: [],
    pending_change: {} as RecurlyPendingChangeData,
    current_period_started_at: "",
    current_period_ends_at: "",
    current_term_started_at: "",
    current_term_ends_at: "",
    trial_started_at: "",
    trial_ends_at: "",
    remaining_billing_cycles: 0,
    total_billing_cycles: 0,
    renewal_billing_cycles: 0,
    auto_renew: true,
    paused_at: "",
    remaining_pause_cycles: 0,
    currency: "",
    revenue_schedule_type: "",
    unit_amount: 99.99,
    quantity: 0,
    add_ons: [],
    add_ons_total: 0,
    subtotal: 0,
    tax: 0,
    tax_info: {},
    total: 0,
    collection_method: "",
    po_number: "",
    net_terms: 0,
    terms_and_conditions: "",
    customer_notes: "",
    expiration_reason: "",
    custom_fields: [],
    created_at: "",
    updated_at: "",
    activated_at: "",
    canceled_at: "",
    expires_at: "",
    bank_account_authorized_at: "",
    gateway_code: "",
    billing_info_id: "",
    active_invoice_id: "",
  };
}
