import store from "@/state/store";

import axios from "axios";
import { authHeaders } from "../content.service";
import cache from "@/services/cache/cache.service";
import { CreateAsset, KcmAsset, UpdateAsset } from "@/types";

export default {
  createCustomAsset,
  updateCustomAsset,
  deleteCustomAsset,
};

const baseUrl = process.env.VUE_APP_CONTENT_API_ROOT
  ? process.env.VUE_APP_CONTENT_API_ROOT.replace(/\/$/, "")
  : "";

function getUserId(): number {
  return store.getters["auth/authUserId"];
}

async function createCustomAsset(asset: CreateAsset): Promise<KcmAsset> {
  const userId = getUserId();
  return await axios
    .post(
      baseUrl + "/industries/1/assets/custom/" + userId + "/",
      asset,
      authHeaders()
    )
    .then((resp) => {
      cache.clear("content");
      return resp.data.asset;
    })
    .catch((err) => {
      throw new Error(
        err.response?.data?.message ?? "Something went wrong saving your asset"
      );
    });
}

async function updateCustomAsset(
  id: number,
  asset: UpdateAsset
): Promise<KcmAsset> {
  const userId = getUserId();
  return await axios
    .post(
      baseUrl + "/industries/1/assets/custom/" + userId + "/" + id + "/",
      asset,
      authHeaders()
    )
    .then((resp) => {
      cache.clear("content");
      return resp.data.asset;
    })
    .catch((err) => {
      throw new Error(
        err.response?.data?.message ?? "Something went wrong saving your asset"
      );
    });
}

async function deleteCustomAsset(assetId: number): Promise<boolean> {
  const userId = getUserId();
  const url = baseUrl + "/industries/1/assets/custom/" + userId + "/" + assetId;
  return axios
    .delete(url, authHeaders())
    .then(() => {
      cache.clear("content");
      return true;
    })
    .catch((err) => {
      throw new Error(err);
    });
}
