import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/market-updates",
    name: "Market Updates",
    meta: { authRequired: true },
    component: () => import("@/router/views/marketUpdates/market-updates.vue"),
  },
  {
    path: "/market-updates/:contentType(mmr)/:slug",
    name: "Market Updates Detail MMR",
    props: true,
    meta: { authRequired: true, featureRequired: "mmr" },
    component: () =>
      import("@/router/views/marketUpdates/market-updates-detail.vue"),
  },
  {
    path: "/market-updates/:contentType(deep-dive)/:slug",
    name: "Market Updates Detail Deep Dive",
    props: true,
    meta: { authRequired: true },
    component: () =>
      import("@/router/views/marketUpdates/market-updates-detail.vue"),
  },
];

const redirects: RouteConfig[] = [
  {
    path: "/content/deep-dive",
    redirect: { path: "/market-updates", query: { types: "deep-dive" } },
  },
  {
    path: "/the-deep-dive",
    redirect: { path: "/market-updates", query: { types: "deep-dive" } },
  },
  {
    path: "/content/deep-dive/:slug",
    redirect: "/market-updates/deep-dive/:slug",
  },
  {
    path: "/mmr",
    redirect: { path: "/market-updates", query: { types: "mmr" } },
  },
  {
    path: "/mmr/:monthYear",
    redirect: { path: "/market-updates", query: { types: "mmr" } },
  },
];

const marketUpdateRoutes = [...routes, ...redirects];

export default marketUpdateRoutes;
