import { Route } from "vue-router/types/router";
import { helpers } from "@/services/helpers";
import { AuthUser } from "@/types";
import { thirdPartyService } from "../internal-api/thirdparty.service";

const Hubspot = {
  name(): string {
    return "hubspot";
  },

  event(
    key: string,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    value?: any
  ): void {
    try {
      const _hsq = (window._hsq = window._hsq || []);
      _hsq.push([key, value]);
    } catch (err) {
      helpers.customSentryError("Unable to initialize Hubspot", "", {
        error: err,
      });
    }
  },

  pageView(data: { to: Route }): void {
    try {
      this.event("setContentType", "site-page");
      this.event("setPath", data.to.fullPath);
      this.event("trackPageView");

      window.HubSpotCallsToActions?.refresh();
    } catch (err) {
      helpers.customSentryError("Unable to initialize Hubspot", "", {
        error: err,
      });
    }
  },

  identify(user: Partial<AuthUser>): void {
    if (!user.id) return window.HubSpotConversations?.widget.remove();

    this.event("identify", {
      email: user.email,
      id: user.id,
    });

    const cachedId = this.validCachedHubspotId(user.id);

    if (cachedId) {
      window.hsConversationsSettings = {
        identificationEmail: user.email,
        identificationToken: cachedId,
      };
      return window.HubSpotConversations?.widget.load();
    }

    thirdPartyService
      .getHubspotToken(user.email ?? "")
      .then((response) => {
        this.cacheHubspotId(user.id ?? "", response.token);

        window.hsConversationsSettings = {
          identificationEmail: user.email,
          identificationToken: response.token,
        };
        window.HubSpotConversations?.widget.load();
      })
      .catch((err) => {
        helpers.customSentryError("hubspot identity token error", err.message, {
          error: err,
        });
      });
  },

  /**
   * Return the token if it exists in sessionStorage, hasn't expired,
   * and matches the logged in user id
   * @param userId
   * @returns
   */
  validCachedHubspotId(userId: string): string | null {
    const cachedValue = sessionStorage.getItem("kcm_hubspot_id");
    if (!cachedValue || cachedValue === "") return null;

    const hsToken = JSON.parse(cachedValue);

    if (
      hsToken?.token !== "" &&
      new Date(hsToken?.expires) > new Date() &&
      hsToken?.userId === userId
    ) {
      return hsToken.token;
    }

    return null;
  },

  /**
   * Store token to session storage because it's good for 12 hours
   * @param userId
   * @param token
   */
  cacheHubspotId(userId: string, token: string): void {
    const hubspotToken = {
      token: token,
      userId: userId,
      expires: new Date(Date.now() + 12 * 60 * 60 * 1000),
    };
    sessionStorage.setItem("kcm_hubspot_id", JSON.stringify(hubspotToken));
  },
};

export default Hubspot;
