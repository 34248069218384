import { billingService } from "@/services/billingservice/billing.service";
import { recurlyService } from "@/services/billingservice/recurly.service";
import { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import store from "@/state/store";

const routes: RouteConfig[] = [
  {
    path: "/account",
    name: "Account",
    meta: { authRequired: true },
    component: () => import("@/router/views/settings/account.vue"),
  },
  {
    path: "/subscription",
    name: "Subscription",
    meta: {
      authRequired: true,
      noPPRequired: true,
    },
    component: () => import("@/router/views/settings/subscription.vue"),
  },
  {
    path: "/billing",
    name: "Billing",
    props: true,
    meta: {
      authRequired: true,
      noPPRequired: true,
    },
    component: () => import("@/router/views/settings/billing.vue"),
  },
  {
    path: "/team-management",
    name: "Team Management",
    meta: { authRequired: true, teamRequired: true },
    component: () => import("@/router/views/settings/team-management.vue"),
  },
  {
    path: "/access-restricted",
    name: "Access Restricted",
    props: true,
    meta: {
      authRequired: true,
      billingRequired: {
        service: recurlyService.serviceId(),
      },
      noPPRequired: true,
    },
    component: () => import("@/router/views/settings/access-restricted.vue"),
  },
  {
    path: "/reactivate",
    name: "Reactivate",
    props: true,
    meta: { authRequired: true, noPPRequired: true },
    component: () =>
      import("@/router/views/settings/reactivate-membership.vue"),
    beforeEnter: (
      _to: Route,
      _from: Route,
      next: NavigationGuardNext
    ): void => {
      if (
        store.getters["auth/authStatus"] !== "inactive" &&
        store.getters["auth/hasFeature"]("!free")
      ) {
        next("/dashboard");
      }
      next();
    },
  },
  {
    path: "/integrations",
    name: "Integrations",
    meta: { authRequired: true },
    component: () => import("@/router/views/settings/social-integrations.vue"),
  },
  {
    path: "/blog/rssfeed",
    name: "My Personalized RSS Feed",
    meta: { authRequired: true },
    component: () => import("@/router/views/blog/rss-feed.vue"),
  },
  {
    path: "/upgrade",
    name: "upgrade",
    meta: {
      authRequired: true,
      billingRequired: {
        service: recurlyService.serviceId(),
        redirect: billingService.redirects()["upgrade"],
      },
      redirectDunning: true,
    },
    component: () => import("@/router/views/settings/upgrade-page.vue"),
    beforeEnter: (_t: Route, _f: Route, next: NavigationGuardNext): void => {
      if (store.getters["auth/isTeamOrEnterpriseOwner"]) {
        next("/dashboard");
        return;
      }

      if (store.getters["billing/billingHasPendingCancel"]) {
        next("/subscription");
        return;
      }

      next();
    },
  },
];

const redirects: RouteConfig[] = [
  { path: "/social-accounts", redirect: "/integrations" },
  {
    path: "/pp/rssfeed",
    redirect: "/blog/rssfeed",
  },
];

const accountRoutes: RouteConfig[] = [...routes, ...redirects];

export default accountRoutes;
