import { detectAnyAdblocker } from "just-detect-adblock";
import store from "@/state/store";
import axios from "axios";
import { AuthUser, KcmBilling } from "@/types";
import { userService } from "@/services/internal-api/auth.service";
import { RawLocation, Route } from "vue-router";
import { billingService } from "@/services/billingservice/billing.service";
import { helpers } from "@/services/helpers";

export const redirectHandler = (
  routeTo: Route,
  user: AuthUser
): string | RawLocation => {
  const authRequired: boolean = routeTo.matched.some(
    (route) => route.meta.authRequired
  );

  // If auth isn't required for the route, just continue.
  if (!authRequired) {
    if (store.getters["auth/loggedIn"]) {
      handleLoggedInUser(routeTo, user);
    }
    return "";
  }

  if (store.getters["auth/loggedIn"]) {
    const redirect = handleLoggedInUser(routeTo, user);
    if (redirect) {
      return redirect;
    } else {
      return "";
    }
  }

  return { name: "login", query: { redirectFrom: routeTo.fullPath } };
};

export const handleLoggedInUser = (
  routeTo: Route,
  user: AuthUser
): string | RawLocation => {
  const userId = user.id ?? "0";

  // check if token is expired, if so then logout
  if (store.getters["auth/tokenIsExpired"]) {
    return { name: "logout", query: { redirectFrom: routeTo.fullPath } };
  }

  // check for a forced redirect, such a reset password, if exists then redirect
  const forceRedirect = store.getters["auth/forceRedirect"];

  if (forceRedirect !== "") {
    return forceRedirect;
  }

  // sync user data, if necessary
  handleSync(routeTo.query.sync as string, userId);

  // handle paused, inactive, limited and incomplete Auth API status
  const needsRedirect = redirectBasedOnAuthStatus(
    routeTo.path,
    user.status ?? "active"
  );
  if (needsRedirect) {
    return needsRedirect;
  }

  if (routeTo.meta) {
    // if the member doesn't have a pp code, they need to setup their profile, otherwise they can continue.
    if (!routeTo.meta.noPPRequired) {
      if (!store.getters["profile/profileHasFlag"](1)) {
        return { name: "setup my profile" };
      }
    }

    // if particular billing is required to view the page, check that the user's has the correct billing source
    if (routeTo.meta.billingRequired) {
      // if the billing source doesn't match, redirect the user to the provided redirect
      if (
        (
          store.getters["billing/billingStoreService"] as KcmBilling
        ).serviceId() !== routeTo.meta.billingRequired.service
      ) {
        return { path: routeTo.meta.billingRequired.redirect };
      }
    }

    // if the user is in dunning and the route doesn't allow dunning (default is allow), redirect
    if (routeTo.meta.redirectDunning) {
      if (
        store.getters["billing/billingAccount"] &&
        store.getters["billing/billingAccount"].has_past_due_invoice
      ) {
        store.getters["billing/billingAccount"];
        return { path: billingService.redirects()["dunning"] };
      }
    }

    // if the user is not a team owner and the route requires you to be a team owner redirect to dashboard
    if (routeTo.meta.teamRequired) {
      if (
        !store.getters["auth/isAnyTeamOwner"] &&
        !store.getters["auth/isTeamAdmin"]
      ) {
        return { name: "dashboard" };
      }
    }

    const featureRequired: string = routeTo.meta.featureRequired;

    // If feature isn't required for the route OR the member has access to the required feature, allow access
    if (!featureRequired || store.getters["auth/hasFeature"](featureRequired))
      return "";

    // otherwise redirect to upgrade page
    return { path: billingService.redirects()["upgrade"] };
  }

  return "";
};

/**
 * The function checks the version of the application and compares it to the latest version, reloading
 * the page if necessary. Non production environments will just console log that it should refresh the page
 * but production would actually refresh.
 */
export async function checkAppVersion(): Promise<void> {
  const myVersion = (process.env.VUE_APP_VERSION as string).split(".");

  if (!myVersion) {
    helpers.customSentryError(
      "Failed version check - no version found.",
      "",
      {}
    );
    return;
  }

  interface BuildData {
    latestBuildDate: string;
    latestVersion: string;
  }

  //get latest version from remote file
  try {
    const host =
      process.env.NODE_ENV === "production"
        ? process.env.VUE_APP_URL
        : window.location.protocol + "//" + window.location.host;

    const latestBuildData = await axios.get(
      host + "build.json?t=" + Date.now()
    );

    const latestVersion = (
      latestBuildData.data as BuildData
    ).latestVersion.split(".");

    const k = Math.min(myVersion.length, latestVersion.length);
    for (let i = 0; i < k; i++) {
      const me = parseInt(myVersion[i], 10);
      const latest = parseInt(latestVersion[i], 10);

      if (latest > me) {
        if (process.env.NODE_ENV === "production") {
          location.reload();
        }
      }
    }
  } catch (_) {
    return;
  }
}

export async function detectAdBlocker(): Promise<void> {
  await detectAnyAdblocker()
    .then(async (detected: boolean) => {
      if (detected) {
        await store.dispatch("settings/changeAdBlockEnabled", true);
      } else {
        await store.dispatch("settings/changeAdBlockEnabled", false);
      }
    })
    .catch(async () => {
      await store.dispatch("settings/changeAdBlockEnabled", false);
    });
}

export const redirectBasedOnAuthStatus = (
  path: string,
  authStatus: string
): string => {
  if (
    authStatus === "active" &&
    store.getters["auth/hasFeature"]("free") &&
    path !== "/reactivate"
  ) {
    return "/reactivate";
  }

  if (authStatus === "active") {
    return "";
  }

  if (authStatus === "incomplete" && path !== "/completeSignup") {
    return "/completeSignup";
  }

  if (authStatus === "inactive" && path !== "/reactivate") {
    return "/reactivate";
  }

  if (authStatus === "limited" && path !== "/access-restricted") {
    return "/access-restricted";
  }

  if (authStatus === "paused" && path !== "/subscription") {
    return "/subscription";
  }

  return "";
};

export const handleSync = (sync: string | string[], userId: string): void => {
  const options = {
    auth: false,
    profile: false,
    billing: false,
    crm: false,
  };

  if (sync?.includes("all")) {
    options.auth = true;
    options.profile = true;
    options.billing = true;
    options.crm = true;
    getSettingsConfig(true);
  } else {
    const _15minago = parseInt(Date.now().toString()) - 900000;
    if (
      sync?.includes("account") ||
      sync?.includes("services") ||
      isNaN(store.getters["auth/lastSync"]) ||
      store.getters["auth/lastSync"] < _15minago
    ) {
      options.auth = true;
    }
    if (
      sync?.includes("profile") ||
      isNaN(store.getters["profile/lastSync"]) ||
      store.getters["profile/lastSync"] < _15minago ||
      Object.keys(store.getters["profile/fullProfile"]).length === 0
    ) {
      options.profile = true;
    }
    if (
      sync?.includes("billing") ||
      isNaN(store.getters["billing/lastSync"]) ||
      store.getters["billing/lastSync"] < _15minago
    ) {
      options.billing = true;
    }
    getSettingsConfig(false);
  }

  userService.syncUserData(userId, options);
};

async function getSettingsConfig(force: boolean): Promise<void> {
  const _10MIN = 10 * 60 * 1000;

  if (
    Date.now() > store.getters["settings/getConfigUpdated"] + _10MIN ||
    !store.getters["settings/siteConfig"] ||
    force
  ) {
    await axios
      .get(`${process.env.VUE_APP_SITE_CONFIG}1/config.json?t=${Date.now()}`)
      .then((response) => {
        if (response.data?.config) {
          store.dispatch("settings/setSiteConfig", response.data.config);
        }
      })
      .catch((e: any) => {
        console.log("Error loading config: ", e);
        return Promise.reject(e);
      });
  }
}
