import store from "@/state/store";
import {
  KcmContent,
  KcmContentUpdate,
  KcmContentArrayResult,
  KcmContentResult,
} from "@/types";
import axios, { AxiosError, AxiosResponse } from "axios";
import {
  ContentQueryParams,
  contentService,
  authHeaders,
} from "../content.service";
import cache from "@/services/cache/cache.service";

export default {
  getContents,
  getContent,
  cloneContent,
  createContent,
  updateContent,
  deleteContent,
};

const industry = "1";
const baseUrl = process.env.VUE_APP_CONTENT_API_ROOT
  ? process.env.VUE_APP_CONTENT_API_ROOT.replace(/\/$/, "")
  : "";

function getUserId(): number {
  return store.getters["auth/authUserId"];
}

function getContents(
  options: ContentQueryParams
): Promise<KcmContentArrayResult> {
  options.user_id = getUserId();

  return contentService.getContents(options);
}

function generateSlug(title: string, userId: number, revision: number): string {
  // Make title lowercase, trim whitespace, and remove special characters
  const cleanedTitle = title
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "") // Remove special characters
    .replace(/\s+/g, "-") // Convert spaces to hyphens
    .replace(/-+/g, "-"); // Convert multiple hyphens to a single hyphen

  // Create the base slug with the user ID
  let slug = `${cleanedTitle}-${userId}`;

  // If the revision is greater than 1, add it to the slug
  if (revision > 1) {
    slug += `-${revision}`;
  }

  slug += `-${Date.now()}`;

  return slug;
}

function getContent(contentId: number | string): Promise<KcmContentResult> {
  const userId = getUserId();
  const url =
    baseUrl +
    "/industries/" +
    industry +
    "/content/" +
    contentId +
    "?user_id=" +
    userId +
    "&language=all";
  return (
    axios
      .get(url, authHeaders())
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      .then((response: AxiosResponse<any, any>) => {
        return response.data;
      })
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      .catch((err: AxiosError<any, any>) => {
        return Promise.reject({
          error: err.response?.data?.message ?? "",
        });
      })
  );
}

function cloneContent(contentId: number): Promise<KcmContentResult> {
  const url =
    baseUrl + "/industries/" + industry + "/content/" + contentId + "/clone";

  return (
    axios
      .post(url, {}, authHeaders())
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      .then((response: AxiosResponse<any, any>) => {
        cache.clear("content");
        return response.data;
      })
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      .catch((err: AxiosError<any, any>) => {
        return Promise.reject({
          error: err.response?.data?.message ?? "",
        });
      })
  );
}

function createContent(
  content: Partial<KcmContent>
): Promise<KcmContentResult> {
  const userId = getUserId();
  const revision = 1;
  const url = baseUrl + "/industries/" + industry + "/content/custom/" + userId;

  const packagedContent: Partial<KcmContentUpdate> =
    contentService.makePostDataFromContent(content);

  packagedContent.slug = generateSlug(content.title ?? "", userId, revision);

  return (
    axios
      .post(url, packagedContent, authHeaders())
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      .then((createResponse: AxiosResponse<any, any>) => {
        cache.clear("content");
        return createResponse.data;
      })
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      .catch((err: AxiosError<any, any>) => {
        return Promise.reject({
          error: err.response?.data?.message ?? "",
        });
      })
  );
}

function updateContent(
  contentId: number,
  content: Partial<KcmContent>
): Promise<KcmContentResult> {
  const packagedContent: Partial<KcmContentUpdate> =
    contentService.makeUpdateFromContent(content);

  const userId = getUserId();
  const url =
    baseUrl +
    "/industries/" +
    industry +
    "/content/custom/" +
    userId +
    "/" +
    contentId;
  return (
    axios
      .post(url, packagedContent, authHeaders())
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      .then((updateResponse: AxiosResponse<any, any>) => {
        cache.clear("content");
        return updateResponse.data;
      })
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      .catch((err: AxiosError<any, any>) => {
        return Promise.reject({
          error: err.response?.data?.message ?? "",
        });
      })
  );
}

function deleteContent(contentId: number): Promise<boolean> {
  const userId = getUserId();
  const url =
    baseUrl +
    "/industries/" +
    industry +
    "/content/custom/" +
    userId +
    "/" +
    contentId;
  return axios
    .delete(url, authHeaders())
    .then(() => {
      cache.clear("content");
      return true;
    })
    .catch(() => {
      return false;
    });
}
