/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from "axios";

type Prefix = "" | "content" | "local";

const cache: Record<string, { data: any; expires: Date }> = {};

export default {
  get: (prefix: Prefix, resource: string): any => {
    const key = `${prefix}-${resource}`;
    purgeExpired(); // remove old data first
    if (
      Object.prototype.hasOwnProperty.call(cache, key) &&
      cache[key].expires?.getTime() > new Date().getTime()
    ) {
      return JSON.parse(JSON.stringify(cache[key].data));
    }
    return null;
  },

  set: (prefix: Prefix, resource: string, data: AxiosResponse): void => {
    const key = `${prefix}-${resource}`;
    cache[key] = {
      data: cloneAxiosResponse(data),
      expires: setExpiration(),
    };
  },

  clear: (prefix: Prefix = ""): void => {
    const pattern = new RegExp(`^${prefix}-`);
    for (const key in cache) {
      if (pattern.test(key)) delete cache[key];
    }
  },

  clearAll: (): void => {
    Object.assign(cache, {});
  },
};

/**
 * Helps avoid issues where the response object we originally saved for a
 * given request gets modified after being cached
 * @param response
 * @returns
 */
const cloneAxiosResponse = (response: AxiosResponse): AxiosResponse => {
  return {
    ...response,
    data: JSON.parse(JSON.stringify(response.data)),
    headers: { ...response.headers },
  };
};

const purgeExpired = (): Promise<void> => {
  const now = new Date().getTime();
  for (const key in cache) {
    if (cache[key].expires.getTime() <= now) {
      delete cache[key];
    }
  }

  return Promise.resolve();
};

const setExpiration = (minutes = 15): Date => {
  return new Date(Date.now() + minutes * 60 * 1000);
};
