import Vue from "vue";
import Vuex from "vuex";

import autoposterModule from "./modules/autoposter";
import authModule from "./modules/auth";
import billingModule from "./modules/billing";
import crmModule from "./modules/crm";
import notificationModule from "./modules/notification";
import profileModule from "./modules/profile";
import settingsModule from "./modules/settings";
import realtalkModule from "@/composables/useRealTalk/useRealTalk.store";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    autoposter: autoposterModule,
    auth: authModule,
    billing: billingModule,
    crm: crmModule,
    notification: notificationModule,
    profile: profileModule,
    settings: settingsModule,
    realtalk: realtalkModule,
  },
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== "production",
});

export default store;
