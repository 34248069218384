import store from "@/state/store";
import { ref } from "vue";

export interface SupportLink {
  copy: string;
  url: string;
}

const supportLinks = ref<SupportLink[]>([]);

async function loadConfigAndSetLinks(currentPath: string): Promise<void> {
  try {
    let config = store.getters["settings/siteConfig"];
    if (!config) {
      const response = await fetch(
        "https://files.keepingcurrentmatters.com/web-assets/staging/1/config.json"
      );
      const data = await response.json();
      config = data.config;
    }

    const matchingKey = Object.keys(config.support_links?.data ?? []).find(
      (key) => new RegExp(key).test(currentPath)
    );

    if (matchingKey) {
      supportLinks.value = config.support_links.data[matchingKey].value;
    } else {
      supportLinks.value = [];
    }
  } catch (error) {
    console.error("Failed to load config:", error);
  }
}

export { supportLinks, loadConfigAndSetLinks };
