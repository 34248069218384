import { NavigationGuardNext, Route, RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/playbook",
    redirect: "/playbooks/1",
  },
  {
    path: "/playbooks",
    name: "Playbooks",
    meta: { authRequired: true },
    component: () => import("@/router/views/playbook/playbook-feed.vue"),
    beforeEnter: (
      _to: Route,
      _from: Route,
      next: NavigationGuardNext
    ): void => {
      // feature detection - or however we need to handle this
      next();
    },
  },
  {
    path: "/playbooks/:slug",
    name: "", // intentionally blank
    props: true,
    meta: { authRequired: true },
    component: () => import("@/router/views/playbook/playbook-detail.vue"),
    beforeEnter: (
      _to: Route,
      _from: Route,
      next: NavigationGuardNext
    ): void => {
      // feature detection - or however we need to handle this
      next();
    },
  },
];

export default routes;
