import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/fub",
    name: "Follow Up Boss",
    meta: { authRequired: false },
    component: () => import("@/router/views/crm-integrations/FollowUpBoss.vue"),
  },
];

export default routes;
